import {createContext, useEffect, useCallback, useMemo, useContext} from 'react';
import { QueryCache } from '@tanstack/react-query';
import * as auth from '../utils/auth';
import { client, fileClient } from '../utils/api-client';
import { useAsync } from '../utils/hooks';

import {LoadingScreen} from '../components/loading_screen';

async function bootstrapAppData() {
  console.log("bootstrapAppData");
  var user = null;

  const token = await auth.getToken();
  console.log("token : ", token);
  if (token) {
    const data = await client('bootstrap', { token });
    console.log("bootstrap data : ", data);
    user = data?.result;
  }
  return user;
}

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
  console.log("*** AuthProvider");
  const { data: user, status, error, isLoading, isIdle, isError,
    isSuccess, run, setData } = useAsync();

  useEffect(() => {
    const appDataPromise = bootstrapAppData();
    run(appDataPromise);
  }, [run]);

  const login = useCallback(
    (form) =>
      auth.login(form).then((response) => {
        setData(response);
        return response;
      }),
    [setData],
  );

  const reauthenticate = useCallback(() => {
    const appDataPromise = bootstrapAppData()
    return run(appDataPromise)
  }, [run])

  const logout = useCallback(() => {
    auth.logout().finally(() => {
      const queryCache = new QueryCache({
        onError: (error) => {
          console.log(error)
        },
      })
      queryCache.clear()
      setData(null)
    })
  }, [setData])

  const value = useMemo(
    () => ({ user, login, logout, reauthenticate }),
    [login, logout, user, reauthenticate],
  )

  if (isLoading || isIdle) {
    //return <FullPageSpinner />
    //return <h1>*Loading...</h1>
    return (
      <LoadingScreen />
    );
  }

  if (isError) {
    console.log('isError error : ', error);
    //return <FullPageErrorFallback error={error} />
    return (
        <h1>Loading error... {error.toString()}</h1>
    )
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Error: ${status}`)
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("Error")
  }
  return context;
}

function useClient() {
  const { user } = useAuth();
  const token = user?.token;
  return useCallback((endpoint, config, type) =>
    client(endpoint, { ...config, token }, type), [token]
  );
}

function useFileClient() {
  const { user } = useAuth()
  const token = user?.token
  return useCallback((endpoint, config, type) => fileClient(endpoint, { ...config, token }, type), [token])
}

async function simpleClient(endpoint, config) {
  const token = await auth.getToken()

  if (token) {
    const data = await client(endpoint, { ...config, token })
    console.log('simpleClient data : ', data)
    return data
  }
  return { error: "missingAutentification" }
}

export { AuthProvider, useAuth, useClient, simpleClient, useFileClient }
