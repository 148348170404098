import * as React from 'react';
import {useNavigate} from 'react-router-dom';

//import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import {useTagDashboard} from '../../utils/tag';

import {ItemBoxMTL100} from "./device_mtl100";
import {ItemBoxTgBt5out} from "./device_tgbt5out";
import {ItemBoxTgBt5in} from "./device_tgbt5in";
import {ItemBoxOtpBt5CCGE} from "./device_otpbt5ccge";
import {ItemBoxOtpBt5CC} from "./device_otpbt5cc";
import {ItemBoxUnknown} from "./device_unknown";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));
//
// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >
//     •
//   </Box>
// );

function ItemBox({item}) {
  const navigate = useNavigate();
  //console.log("item : ", item);

  const handleMoreClick = (i) => {
    navigate(`/device/${i.uuid}`, { replace: false });
  }

  if (item.device_model === 'TG-BT5-OUT') {
    return (<ItemBoxTgBt5out item={item} onClick={handleMoreClick}/>);
  } else
  if (item.device_model === 'MTL100') {
    return (<ItemBoxMTL100 item={item} onClick={handleMoreClick}/>);
  }
  if (item.device_model === 'OTP-BT5-CC-GE') {
    return (<ItemBoxOtpBt5CCGE item={item} onClick={handleMoreClick}/>);
  }
  if (item.device_model === 'OTP-BT5-CC') {
    return (<ItemBoxOtpBt5CC item={item} onClick={handleMoreClick}/>);
  }
  if (item.device_model === 'TG-BT5-IN') {
    return (<ItemBoxTgBt5in item={item} onClick={handleMoreClick}/>);
  }
  else {
    return (<ItemBoxUnknown item={item} onClick={handleMoreClick}/>);
  }

  // return (
  //   <React.Fragment>
  //     <CardContent>
  //       <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
  //         Word of the Day
  //       </Typography>
  //       <Typography variant="h5" component="div">
  //         be{bull}nev{bull}o{bull}lent
  //       </Typography>
  //       <Typography sx={{ mb: 1.5 }} color="text.secondary">
  //         adjective
  //       </Typography>
  //       <Typography variant="body2">
  //         well meaning and kindly.
  //         <br />
  //         {'"a benevolent smile"'}
  //       </Typography>
  //     </CardContent>
  //     <CardActions>
  //       <Button size="small">Learn More</Button>
  //     </CardActions>
  //   </React.Fragment>
  // )
}

function DashboardGrid() {
  var {data, status, isSuccess} = useTagDashboard(5000);
  console.log("data : ", data);

  console.log("status : ", status);
  // if (isFetching) {
  //   return (
  //     <Box sx={{ display: 'flex' }}>
  //       <CircularProgress />
  //     </Box>
  //   )
  //
  //   // return (
  //   //   <Box sx={{ display: 'flex' }}>
  //   //     <Skeleton variant="rectangular" width={210} height={60} />
  //   //   </Box>
  //   // )
  //
  // }

  // if (isSuccess) {
  //   return (
  //     <Box sx={{ flexGrow: 1 }}>
  //       <Grid container spacing={1}>
  //         {
  //           data.map((i) => (
  //             <Paper sx={{height: 300, width: 270}}>
  //             <Grid key={i.uuid} >
  //               <ItemBox item={i}/>
  //             </Grid>
  //           </Paper>
  //           ))
  //         }
  //       </Grid>
  //     </Box>
  //   );
  // }

  if (isSuccess) {
    return (
      <Box sx={{ flexWrap: 'wrap', display: "flex" }}>
        {data.map((i) => (
          <Paper sx={{width: 300, m : 1}} key={i.uuid}>
            <ItemBox item={i}/>
          </Paper>
        ))}
      </Box>
    )
  }
}

export default function BasicGrid() {
  return (
    <DashboardGrid/>
  );
}
