import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Button, DialogActions, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system'
import { useAuth } from '../context/auth-context'

function Login() {
  const [account, setAccount] = useState({username: '', password: ''});
  const [error, setError] = useState('');
  const {user, login, logout} = useAuth();
  let navigate = useNavigate();

  function handleChange(e) {
    setAccount({ ...account, [e.target.name]: e.target.value })
  }

  // async function handleCancel() {
  //   await logout()
  //   //reauthenticate()
  // }

  async function doLogin() {
    console.log("doLogin");
     setError('');

     try {
       var r = await login(account);
       console.log("doLogin r : ", r);
       navigate('/', { replace: true })
     } catch (e) {
        console.log("doLogin e : ", e);
        setError(e);
     }

    // login(account).then((r) => {
    //   console.log('login res: ', r)
    //   if (r.error) {
    //     console.log("r.error : ", r.error);
    //     setError(r?.error ?? { data: 'operation_failed' });
    //     return;
    //   }
    //
    //   if (!r || r.error) {
    //     setError(r?.error ?? { data: 'operation_failed' })
    //   } else {
    //     //navigate('/', { replace: true })
    //   }
    // })
  }

  return (
    <div className="center-screen">
      <Paper elevation={3} sx={{ maxWidth: 345 }}>
        {user && user.type && (
          <Button onClick={logout} size="large">
            {('login.logout')}
          </Button>
        )}
        {(!user || !user.type) && (
          <Box
            sx={{
              p: 2,
              '& .MuiTextField-root': { mt: 2, width: '100%' },
            }}
          >
            <Typography variant="h6" align="center">
              {('login')}
            </Typography>
            <TextField
              name="username"
              value={account.username}
              onChange={handleChange}
              label={('username')}
            />
            <TextField name="password" hinttext={('password')}
              floatinglabeltext="Password" type="password"
              value={account.password}
              onChange={handleChange}
              label={('password')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') doLogin()
              }}
            />
            {error && (
              <Typography variant="caption" align="center" color="red">
                {error}
              </Typography>
            )}
            <DialogActions>
              <Button onClick={doLogin} size="large">
                {('login')}
              </Button>
            </DialogActions>
          </Box>
        )}
      </Paper>
    </div>
  )
}

export { Login }
