//import {queryCache} from '@tanstack/react-query'
import {QueryCache} from '@tanstack/react-query'
import * as auth from './auth'
//const apiURL = process.env.REACT_APP_API_URL
//const geocodingURL = process.env.REACT_APP_GEOCODING_URL

const apiURL = process.env.REACT_APP_API_URL;

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error)
  },
  onSuccess: (data) => {
    console.log(data)
  },
  onSettled: (data, error) => {
    console.log(data, error)
  },
})

async function client(endpoint,
    {data, token, headers: customHeaders, ...customConfig} = {}) {

  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
//      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  if (data) {
    config.headers['Content-Type'] = data ? 'application/json' : undefined;
  }
  //---------------------------------------------
  //console.log("Fetch : ", `${apiURL}/${endpoint}`, config);
  return window.fetch(`${apiURL}/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      queryCache.clear();
      await auth.logout();
      // refresh the page for them
      window.location.assign(window.location);
      return Promise.reject({message: 'Please re-authenticate.'});
    }

    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  })
}

async function fileClient(
  endpoint,
  {data, token, headers: customHeaders, ...customConfig} = {},
  type
) {

const config = {
  method: data ? 'POST' : 'GET',
  body: data ? JSON.stringify(data) : undefined,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
//      'Content-Type': data ? 'application/json' : undefined,
    ...customHeaders,
  },
  ...customConfig,
}

if (data) {config.headers['Content-Type'] = data ? 'application/json' : undefined;}

//let filename
return window.fetch(`${apiURL}/${endpoint}`, config).then(resp => {
    console.log('resp main: ', resp)
    // filename = resp.headers.get("content-disposition");
    // console.log('filename: ', filename)
    // filename = filename.match(/(?<=")(?:\\.|[^"\\])*(?=")/)[0];
    return resp.blob()
  })
  .then(blob => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = "route-report.pdf";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  //alert("your file has downloaded!"); // or you know, something with better UX...
  //ToDo sucess message
  return {fileName:'route-report.pdf'}
  })
  .catch((error) => {
    console.error('error: ', error)
    return {error}
  });



// .then(async response => {
//     console.log('fetch response: ', response)
//   if (response.status === 401) {
//     queryCache.clear()
//     await auth.logout()
//     // refresh the page for them
//     window.location.assign(window.location)
//     return Promise.reject({message: 'Please re-authenticate.'})
//   }
//   //const data = await response.json()
//   if (response.ok) {
//     console.log('response ok')
//     return data
//   } else {
//     return Promise.reject(response)
//   }
// })
}

export {client, fileClient}
