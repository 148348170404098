import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import QueryStatsIcon from '@mui/icons-material/QueryStats';


function ItemBoxMTL100({item, onClick}) {
  var handleClick = () => {
    if (onClick) onClick(item);
  }

  const name = item.name ? item.name : item.ident;
  var ld = item.last_tag_data;

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardHeader avatar={
        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
          {name.substring(0, 1)}
        </Avatar>
      }
      action={
        <IconButton aria-label="settings" onClick={handleClick}>
          <QueryStatsIcon />
        </IconButton>
      }
      title={name}
      subheader={`Activity : ${item.last_activity}`}
    />
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {name}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {
            ld && (
              ld["BUILD_IN_TEMPERATURE"]===-128
                ? `Temp : Not available`
                : `Temp : ${ld["BUILD_IN_TEMPERATURE"]}°`
              )
          }
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {ld && (
              ld["BUILD_IN_HUMIDITY"]===-128
                ? `Humidity : Not available`
                : `Humidity : ${ld["BUILD_IN_HUMIDITY"]}%`
              )}
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          {ld && (
              ld["BUILD_IN_LIGHT_LUX"]===-128
                ? `Light lux : Not available`
                : `Light lux : ${ld["BUILD_IN_LIGHT_LUX"]}lx`
            )}
        </Typography>

        <Typography sx={{mb: 1.5}} color="text.secondary">
          Battery : { ld && (
              ld["BATTERY_LEVEL"]+'%'
            )}
        </Typography>
      </CardContent>
    </Card>
  )
}


export {
  ItemBoxMTL100
}
