import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LineChart } from '@mui/x-charts/LineChart';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useSensorData, useDeviceProperties} from '../../utils/device';

import DateSelector from '../date_selector';

import {DeviceMTL100} from "./device_mtl100";

function Chart({uuid}) {
  var {data, isFetching} = useSensorData(uuid);
  //console.log("Chart data : ", data);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  var plotRes = [];
  var x = 0;
  for (var i = 0; i < data.length; i++) {
    if (data[i].tag_data) {
      x += 1;
      plotRes.push({
        temp : data[i].tag_data.TEMPERATURE,
        //data_timestamp : new Date(data[i].formated_tim)
        //data_timestamp : data[i].formated_tim,
        date : new Date(data[i].formated_tim),
        inc : x
      });
    }
      //data[i]["temp"] = data[i].tag_data.TEMPERATURE;
  }
  console.log("plotRes : ", plotRes);

  return (
  <LineChart
    dataset={plotRes}
    xAxis={[{
      dataKey: 'date',
      valueFormatter: (date) => {
        var d = new Date(date);
        var datestring = d.getHours()+":"+d.getMinutes();
        return datestring
      }
    }]}
    series={[{
      dataKey: 'temp',
      showMark: false,
    }]}
    yAxis={[
      {
        min: -30,
        max: 50,
        disableLine: true,
        disableTicks: true,
        valueFormatter: (value, context) => {
//          console.log("yAxis value : ", value, " context : ", context);
          return `${value}°`
        },
        colorMap: {
          type: 'continuous',
          color: (d) => {
            if (d > 0) {
              return 'red';
            } else {
              return 'blue';
            }
          }
        },
      },
    ]}
    height={300}
    margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
    grid={{ vertical: true, horizontal: true }}
  />
);
}

// function SimpleChart({data, dataKey, min, max, unit}) {
//   return (
//     <LineChart
//       dataset={data}
//       xAxis={[{
//         dataKey: 'date',
//         valueFormatter: (date) => {
//           var d = new Date(date);
//           var datestring = d.getHours()+":"+d.getMinutes();
//           return datestring
//         }
//       }]}
//       series={[{
//         dataKey: dataKey,
//         showMark: false,
//       }]}
//       yAxis={[
//         {
//           min: min,
//           max: max,
//           disableLine: true,
//           disableTicks: true,
//           valueFormatter: (value, context) => {
//             return `${value}${unit}`
//           },
//           colorMap: {
//             type: 'continuous',
//             color: (d) => {
//               if (d > 0) {
//                 return 'red';
//               } else {
//                 return 'blue';
//               }
//             }
//         },
//       },
//     ]}
//     height={300}
//     margin={{ left: 40, right: 30, top: 30, bottom: 30 }}
//     grid={{ vertical: true, horizontal: true }}
//   />
// );
// }

function DeviceContainer({attributes, uuid}) {
  const [dateRange, setDateRange] = useState('1_day');
  const [dateFrom, setDateFrom] = useState(new Date());

  console.log("dateRange : ", dateRange, " dateFrom : ", dateFrom);

  const handleDateSelectorChange = (dateRange, dateFrom) => {
    console.log("handleDateSelectorChange : ", dateRange, dateFrom);
    setDateRange(dateRange);
    setDateFrom(dateFrom);
  }

  var activeDevice;
  if (attributes.device_model	=== 'MTL100') {
    activeDevice = <DeviceMTL100 uuid={uuid} attributes={attributes} dateRange={dateRange} dateFrom={dateFrom}/>;
  } else {
    activeDevice = <Chart uuid={uuid} attributes={attributes}/>;
  }

  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <DateSelector onChange={handleDateSelectorChange} rangeDefault={dateRange} dateFrom={dateFrom}/>
      </Box>
      {activeDevice}
    </div>
  );
}

function DeviceScreen() {
  const {uuid} = useParams();
  console.log("uuid : ", uuid);

  var {data, isFetching} = useDeviceProperties(uuid);
  console.log("DeviceScreen data : ", data);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <DeviceContainer uuid={uuid} attributes={data}/>
  );
}

export {
  DeviceScreen
}
