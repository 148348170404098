import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import {useAuth} from './context/auth-context';
import {Login} from './components/login.js';

import Dashboard from './components/dashboard';
import {DeviceScreen} from './components/device';
import {LoadingScreen} from './components/loading_screen';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
//import RestoreIcon from '@mui/icons-material/Restore';
//import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import SellIcon from '@mui/icons-material/Sell';
import RouterIcon from '@mui/icons-material/Router';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';

import 'moment/locale/lv';
//import './App.css';

function LabelBottomNavigation() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    navigate(`/${newValue}`, { replace: true });
    setValue(newValue);
  };

  return (
    <BottomNavigation sx={{ width: '100%' }} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Dashboard"
        value="dashboard"
        icon={<DashboardIcon />}
      />
      <BottomNavigationAction
        label="Tag"
        value="tag"
        icon={<SellIcon />}
      />
      <BottomNavigationAction
        label="Geteway"
        value="gateway"
        icon={<RouterIcon />}
      />
      <BottomNavigationAction
        label="Where Is"
        value="where_is"
        icon={<LocationOnIcon />}
      />
      <BottomNavigationAction
        label="Event"
        value="event"
        icon={<EventNoteIcon />}
      />
      <BottomNavigationAction
        label="Folder"
        value="folder"
        icon={<FolderIcon />}
      />
      <BottomNavigationAction
        label="Settings"
        value="settings"
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
  );
}

function About() {
  return <div>About</div>;
}

function Content() {

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="lv">
      <Container maxWidth="xl">
        <LabelBottomNavigation/>

        <Routes>
          <Route path="/" element={<Dashboard/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/device/:uuid" element={<DeviceScreen/>} />
          <Route path="about" element={<About />} />
        </Routes>
      </Container>
    </LocalizationProvider>
  );
}

function App() {
  var {user} = useAuth();
  console.log("user : ", user);

  return (
    <React.Suspense fallback={<LoadingScreen/>}>
      <div className="App">
        {user
          ? <Content/>
          : <Login/>
        }
      </div>
    </React.Suspense>
  );
}

export default App;
