import {simpleClient} from '../context/auth-context.js';
import {useQuery} from '@tanstack/react-query';

// function useSensorData(uuid) {
//   const r = useQuery(['useSensorData', uuid], () => {
//     return simpleClient(`sensor/${uuid}`).then(d => d.res);
//   });
//   return r;
// }
//useSensorData(uuid, dateRange, date)
function useSensorData(uuid, dateRange, date) {
  const r = useQuery({
    queryKey: ['useSensorData', uuid, dateRange, date],
    queryFn: async () => {
      return simpleClient(`sensor/${uuid}`,
        {data : {date_from : date, range : dateRange}}
      ).then(d => d.res);
    },
    refetchOnWindowFocus: false
  });
  return r;
}

// function useDeviceProperties(uuid) {
//   const r = useQuery(['useSensorProperties', uuid], () => {
//     return simpleClient(`sensor/${uuid}/properties`).then(d => d.res);
//   });
//   return r;
// }

function useDeviceProperties(uuid) {
  const r = useQuery({
    queryKey: ['useDeviceProperties', uuid],
    queryFn: async () => {
      return simpleClient(`sensor/${uuid}/properties`).then(d => d.res);
    },
    refetchOnWindowFocus: false
  });
  return r;
}

export {
  useSensorData, useDeviceProperties
}
