import {simpleClient} from '../context/auth-context.js';
import {useQuery} from '@tanstack/react-query';

///api/tag/list
function useTagDashboard(intervalMs) {
  // const r = useQuery(['useTagDashboard'], () => {
  //   return client(`tag/list`).then(d => d.res);
  // });
  //
  // return r;


  const r = useQuery({
    queryKey: ['useTagDashboard', intervalMs],
    queryFn: async () => {
      return simpleClient(`tag/list`).then(d => d.res);
    },
    refetchInterval: intervalMs,
  });

  return r;
}

export {
  useTagDashboard
}
