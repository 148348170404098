import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

function ItemBoxUnknown({item, onClick}) {

  var handleClick = () => {
    if (onClick) onClick(item);
  }

  const name = item.name ? item.name : item.ident;

  return (
    <Card variant="outlined" sx={{height: "100%"}}>
      <CardHeader avatar={
        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
          {name.substring(0, 1)}
        </Avatar>
      }
      action={
        <IconButton aria-label="settings" onClick={handleClick}>
          <QueryStatsIcon />
        </IconButton>
      }
      title={name}
      subheader={`Activity : ${item.last_activity}`}
    />
      <CardContent>
        <Typography sx={{fontSize: 14, bgcolor: 'error.main'}} color="text.secondary" gutterBottom>
          Unknown device
        </Typography>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Name : {item.name ? item.name : item.ident}
        </Typography>
      </CardContent>
    </Card>
  )
}

export {
  ItemBoxUnknown
}
