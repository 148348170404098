import React from 'react';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import RestoreIcon from '@mui/icons-material/Restore';

import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function RowRadioButtonsGroup({onChange, rangeDefault, dateFrom}) {
  const [range, setRange] = useState(rangeDefault);
  const [date, setDate] = useState(moment(dateFrom));

  console.log("range_default : ", rangeDefault, " date_from : ",dateFrom);

  const handelChangeDate = (e) => {
    setDate(e);
    if (onChange) onChange(range, e);
  }

  const handleChangeRange = (e) => {
    setRange(e.target.value);
    if (onChange) onChange(range, date);
  }

  const handleClick = (e) => {
    setRange(rangeDefault);
    setDate(moment(dateFrom));

    if (onChange) onChange(rangeDefault, null);
  }

  return (
    <FormControl>
      <Stack direction="row" spacing={2}>
        <DatePicker maxDate={moment(new Date())} value={date} onChange={handelChangeDate} />
        <IconButton aria-label="delete" size="large" onClick={handleClick}><RestoreIcon/></IconButton>
      </Stack>
      <RadioGroup row name="row-radio-buttons-group" value={range}
        onChange={handleChangeRange}>
          <FormControlLabel value="1_day" control={<Radio />} label="1 day" />
          <FormControlLabel value="1_week" control={<Radio />} label="1 week" />
          <FormControlLabel value="1_month" control={<Radio />} label="1 month" />
      </RadioGroup>
    </FormControl>
  );
}
