import * as React from 'react';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { blue, red } from '@mui/material/colors';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PinDropIcon from '@mui/icons-material/PinDrop';

import moment from 'moment';

import Control from 'react-leaflet-custom-control'
import {Marker, Popup, useMap} from 'react-leaflet';
import {MapContainer} from 'react-leaflet/MapContainer'
import {TileLayer} from 'react-leaflet/TileLayer'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import {useSensorData} from '../../utils/device';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement,
  Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement,
  Title, Tooltip, Filler, Legend);


L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function svgGen(number, color) {
  var c = color?color:blue[500];
  var svgIcon = L.divIcon({
    html: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 61 85" enable-background="new 0 0 61 85" xml:space="preserve">

        <path fill="#333" d="M31.75,0C48.318,0,61,12.488,61,29.057V30c0,21.834-19.322,49-29.75,55H31C20.572,79,0,51.834,0,30v-0.943  C0,12.488,13.932,0,30.5,0C30.667,0,31.583,0,31.75,0z"/>
        <path fill="${c}" d="M31.688,2C47.428,2,59,13.989,59,29.729v0.896C59,51.367,41.119,77,31.212,83h-0.237  C21.069,77,2,51.367,2,30.625v-0.896C2,13.989,14.76,2,30.5,2C30.659,2,31.529,2,31.688,2z"/>
        <text x="50%" y="50%" dy=".13em" font-size="30" font-weight="bold"
          text-anchor="middle" fill="#FFF">${number}</text>
    </svg>`,
    className: "",
    iconSize: [24, 40],
    iconAnchor: [12, 20],
  });

  return svgIcon;
}

function MapPointList({list, onPointSelect}) {
  const handleListPointClick = (el) => {
    console.log("handleListPointClick : ", el);
    if (onPointSelect) onPointSelect(el);
  }

  var pointList = list.map((el) => {
    return (
      <ListItem key={el.uuid} onClick={()=>{handleListPointClick(el)}}>
        <ListItemAvatar>
          <Avatar><PinDropIcon/></Avatar>
        </ListItemAvatar>
        <ListItemText primary={`speed ${el.speed}`}
        secondary={moment(el.utc_time).format('Do MMMM YYYY, HH:mm:ss ')} />
      </ListItem>
    );
    // return (
    //   <Marker key={el.utc_time} position={[el.latitude, el.longitude]}>
    //     <Popup>speed {el.speed} at {el.utc_time}</Popup>
    //   </Marker>
    // );
  });

//   sx={{
//   width: '100%',
//   maxWidth: 360,
//   bgcolor: 'background.paper',
//   position: 'relative',
//   overflow: 'auto',
//   maxHeight: 300,
//   '& ul': { padding: 0 },
// }}

  return (
    <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper',
        position: 'relative', overflow: 'auto', maxHeight: 360}}>
      {pointList}
    </List>
  );
}

function Map({points, centerPoint}) {
  const [lastPos, setLastPos] = useState(centerPoint);

  function LocationMarker() {
    var res = [];
    for (let i = 0; i < points.length; i++) {
      var el = points[i];

      if (i === 108) {
        res.push(
            <Marker key={el.uuid} position={[el.latitude, el.longitude]}>
                <Popup>speed {el.speed} at {moment(el.utc_time).format('Do MMMM YYYY, HH:mm:ss ')}</Popup>
            </Marker>
          );

      } else {

      res.push(
          <Marker key={el.uuid} position={[el.latitude, el.longitude]}
            icon={svgGen(i, i===points.length-1 ? red[500]:blue[500])}>
              <Popup>speed {el.speed} at {moment(el.utc_time).format('Do MMMM YYYY, HH:mm:ss ')}</Popup>
          </Marker>
        );
      }
    }

    return res;
    // var i = 0;
    // var x = points.map((el) => {
    //   i++;
    //   return (
    //     <Marker key={el.uuid} position={[el.latitude, el.longitude]} icon={svgGen(i)}>
    //       <Popup>speed {el.speed} at {moment(el.utc_time).format('Do MMMM YYYY, HH:mm:ss ')}</Popup>
    //     </Marker>
    //   );
    // });
    // return x;
  }

  const handleCenterSet = (el) => {
    console.log("handleCenterSet el : ", el);
    var p = {lat: parseFloat(el.latitude), lng: parseFloat(el.longitude)};
    //var p = [parseFloat(el.latitude), parseFloat(el.longitude)];
    console.log("p : ", p);
    setLastPos(p);
  }

  const SetViewOnCenterUpdate = ({center}) => {
    const map = useMap();
    useEffect(() => {
        //map.setView([center.lat, center.lng]);
        map.setView([center.lat, center.lng], map.getZoom(), {animate: true})
     }, [center.lat, center.lng, map]);
     return null;
  }

  return (
    <Box sx={{ height: '200px' }}>
    <MapContainer center={lastPos} zoom={13} scrollWheelZoom={true}
        style={{ width: "100%", height: "calc(100vh - 4rem)" }}
        attributionControl={false}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
      <Control prepend position='topright'>
        <MapPointList list={points} onPointSelect={handleCenterSet}/>
      </Control>

      <LocationMarker/>
      <SetViewOnCenterUpdate center={lastPos} />
    </MapContainer>
  </Box>
  );
}

function DevLineChart({data, dataKey, min, max, unit, caption}) {

  const opts = {
    responsive: true,
    scales: {
      y : {
        suggestedMin:min,
        suggestedMax:max,
      },
      // x: {
      //           type: 'time',
      //           time: {
      //               unit: 'month'
      //           }
      //       }
    },
    parsing: {
      xAxisKey: 'date_str',
      yAxisKey: dataKey
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: caption ? caption : "Sensor",
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     // pinch: {
      //     //   enabled: true
      //     // },
      //     mode: 'x',
      //     speed: 100
      //     //threshold : 100
      //   },
      //   pan: {
      //     enabled: true,
      //     mode: "x",
      //     speed: 0.5
      //   }
      // }
    }
  };

  const lineData = {
    datasets: [
      {
        fill: false,
        label: caption ? caption : "Sensor",
        data : data,
        //data: [{x: '2016-12-25', y: 20}, {x: '2016-12-26', y: 10}],
        // data: keys.map((key) => {
        //   console.log("key : ", key );
        //   //console.log("data[key][dataKey] : ", data[key][dataKey]);
        //   return data[key][dataKey]
        // }),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  };

  return (
    <Line options={opts} data={lineData} />
  )
}

function DeviceMTL100({uuid, attributes, dateRange, dateFrom}) {
  console.log("dateRange, date : ", dateRange, dateFrom);
  var {data, isFetching} = useSensorData(uuid, dateRange, dateFrom);
  console.log("DeviceMTL100 Chart data : ", data);

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  var pointsTagData = [];
  var sensorTagData = [];
  var sensorTagDataObj = {};

  for (let i = 0; i < data.length; i++) {
//    if (i > 100) break;

    var el = data[i];
    //console.log("data[i] : ", data[i]);
    if (el.key === "SENSOR") {
      // var sensor = el.value;
      // var d = new Date(el.value_timestamp);
      // var userTimezoneOffset = d.getTimezoneOffset() * 60000;
      // sensor["date"] = new Date(d.getTime() + userTimezoneOffset);
      // sensor["date_str"] = sensor["date"].toLocaleString('lv-LV', { timeZone: 'UTC' });
      // sensor["build_in_light_lux"] = parseFloat(sensor["build_in_light_lux"]);
      // sensor["build_in_temperature"] = parseFloat(sensor["build_in_temperature"]);
      // sensorTagData.push(sensor);


      var sensor = el.value;
      var d = new Date(el.value_timestamp);
      sensor["uuid"] = el.uuid;
      sensor["date"] = d;
      //sensor["date_str"] = sensor["date"].toLocaleString('lv-LV', { timeZone: 'UTC' });
      sensor["date_str"] = sensor["date"].toLocaleString('lv-LV');
      //sensor["date_str"] = sensor["date"].toString();
      sensor["build_in_light_lux"] = parseFloat(sensor["build_in_light_lux"]);
      sensor["build_in_temperature"] = parseFloat(sensor["build_in_temperature"]);
      sensorTagData.push(sensor);

    }

    if (el.key === "GNSS_DATA") {
      //console.log("data[i] : ", data[i]);
      var q = el.value;
      q["uuid"] = el.uuid;
      pointsTagData.push(q);
      //   pointsTagData.push({
      //     "latitude" : gnssData.latitude,
      //     "longitude" : gnssData.longitude,
      //     "speed" : gnssData.speed,
      //     "time" : gnssData.utc_time,
      //     "key" : data[i]["uuid"]+"_gnss_data"
      //   });
    }

    // var sensor = data[i]["tag_data"]["SENSOR"];
    // var gnssData = data[i]["tag_data"]["GNSS_DATA"];
    // if (sensor) {
    //   var d = new Date(sensor["sample_rtc_time"]);
    //   var userTimezoneOffset = d.getTimezoneOffset() * 60000;
    //   sensor["date"] = new Date(d.getTime() + userTimezoneOffset);
    //   sensor["build_in_light_lux"] = parseFloat(sensor["build_in_light_lux"]);
    //   sensor["build_in_temperature"] = parseFloat(sensor["build_in_temperature"]);
    //   sensorTagData.push(sensor);
    // }
    // if (gnssData) {
    //   pointsTagData.push({
    //     "latitude" : gnssData.latitude,
    //     "longitude" : gnssData.longitude,
    //     "speed" : gnssData.speed,
    //     "time" : gnssData.utc_time,
    //     "key" : data[i]["uuid"]+"_gnss_data"
    //   });
    // }
  }
  // console.log("sensorTagData : ", sensorTagData);
  //console.log("pointsTagData : ", pointsTagData);
  // console.log("sensorTagDataObj : ", sensorTagDataObj);

  var lPoint = pointsTagData[pointsTagData.length-1];
  var lastPoint = lPoint ? {lat: lPoint.latitude, lng: lPoint.longitude} : {lat: 56.9677, lng: 24.1056};

  return (
    <Box>
      <DevLineChart data={sensorTagData} caption={"Build in temperature sensor"}
        dataKey="build_in_temperature" min={-30} max={70} unit="°"/>

      <DevLineChart data={sensorTagData} caption={"Build in humidity sensor"}
        dataKey="build_in_humidity" min={0} max={100} unit="%"/>

      <DevLineChart data={sensorTagData} caption={"Build in light sensor"}
        dataKey="build_in_light_lux" min={0} max={700} unit="lx"/>

      <Map points={pointsTagData} centerPoint={lastPoint}/>
    </Box>
  )
}

export {
  DeviceMTL100
}
